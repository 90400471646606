import * as yup from 'yup';
import { TFunction } from 'react-i18next';
import dayjs from 'dayjs';
import { supportedLanguages } from './constants';

const now = () => {
  return dayjs();
};
const yesterday = () => {
  return dayjs().subtract(1, 'days');
};
const emailValidation = (t: TFunction<'validation'>) =>
  yup
    .string()
    .required(t('required'))
    .typeError(t('invalid'))
    .email(t('invalid'))
    .min(4, t('too-short'))
    .max(255, t('too-long'));

const passwordValidation = (t: TFunction<'validation'>) =>
  yup
    .string()
    .required(t('required'))
    .min(8, t('too-short'))
    .max(255, t('too-long'));

const confirmPasswordValidation = (
  t: TFunction<'validation'>,
  refName: string
) => yup.string().oneOf([yup.ref(refName)], t('passwords-not-match'));

const varcharValidation = (t: TFunction<'validation'>) =>
  yup
    .string()
    .required(t('required'))
    .typeError(t('invalid'))
    .min(2, t('too-short'))
    .max(255, t('too-long'));

export const validatePropertyAddress = (t: TFunction<'validation'>) => ({
  latitude: yup
    .number()
    .typeError(t('invalid'))
    .required(t('required'))
    .min(-90)
    .max(90),
  longitude: yup
    .number()
    .typeError(t('invalid'))
    .required(t('required'))
    .min(-180)
    .max(180),
  addressLine1: varcharValidation(t),
  addressLine2: yup.string().typeError(t('invalid')).max(255, t('too-long')),
  country: yup.object().typeError(t('invalid')).required(t('required')),
  region: varcharValidation(t),
  city: varcharValidation(t),
  district: varcharValidation(t),
});

const optionalVarcharValidation = (t: TFunction<'validation'>) =>
  yup.string().nullable().typeError(t('invalid')).max(255, t('too-long'));

const varNumberValidation = (t: TFunction<'validation'>) =>
  yup
    .number()
    .required(t('required'))
    .typeError(t('invalid'))
    .min(0, t('positive'));

export const loginSchema = (t: TFunction<'validation'>) => ({
  email: emailValidation(t),
  password: passwordValidation(t),
  language: yup.string().oneOf(supportedLanguages),
  recaptchaToken: yup.string().nullable(),
  testToken: yup.string().nullable(),
  token: yup.string().nullable(),
});

export const registerSchema = (t: TFunction<'validation'>) => ({
  email: emailValidation(t),
  password: passwordValidation(t),
  firstName: varcharValidation(t),
  lastName: varcharValidation(t),
  country: yup.object().typeError('invalid').required(t('required')),
  company: yup.string().typeError('invalid').max(255, t('too-long')),
  numberOfProperties: yup.object().typeError('invalid').required(t('required')),
  phone: varcharValidation(t),
  role: yup.string().typeError('invalid').required(t('required')),
  language: yup.string().oneOf(supportedLanguages),
  recaptchaToken: yup.string().nullable(),
  testToken: yup.string().nullable(),
});

export const resetPasswordSchema = (t: TFunction<'validation'>) => ({
  email: emailValidation(t),
});

export const newPasswordSchema = (t: TFunction<'validation'>) => ({
  password: passwordValidation(t),
  passwordConfirmation: confirmPasswordValidation(t, 'password'),
  token: yup.string().required(t('required')),
});

export const changePasswordSchema = (t: TFunction<'validation'>) => ({
  oldPassword: yup.string().required(t('required')),
  newPassword: passwordValidation(t),
  newPasswordRep: confirmPasswordValidation(t, 'newPassword'),
});

export const updateProfileSchema = (t: TFunction<'validation'>) => ({
  password: yup.mixed().nullable(),
  email: yup.string().typeError(t('invalid')).email(t('invalid')),
  firstName: varcharValidation(t),
  lastName: varcharValidation(t),
  country: yup.object().typeError('invalid').required(t('required')),
  phone: varcharValidation(t),
  nationalId: varcharValidation(t),
  dateOfBirth: yup.date().typeError('invalid').required(t('required')),
  address: yup.string().typeError('invalid').required(t('required')),
  gender: yup
    .string()
    .typeError(t('invalid'))
    .required(t('required'))
    .oneOf(['male', 'female'], t('invalid')),
});

export const supportFormSchema = (t: TFunction<'validation'>) => ({
  email: emailValidation(t),
  firstName: varcharValidation(t),
  lastName: varcharValidation(t),
  answerOn: yup
    .string()
    .typeError(t('invalid'))
    .oneOf(['inbox', 'email'], t('invalid'))
    .required(t('required')),
  question: varcharValidation(t).max(999, t('invalid')),
});

export const addBeneficiaryFormSchema = (t: TFunction<'validation'>) => ({
  id: yup.number().typeError(t('invalid')),
  beneficiaryNameEn: varcharValidation(t),
  beneficiaryNameAr: varcharValidation(t),
  bank: yup.object().required(t('invalid')).typeError(t('invalid')),
  iban: varcharValidation(t).matches(/^SA\d{4}[A-Z0-9]{18}$/, { message: "IBAN is invalid" })
});

export const addBuildingGeneralInfoSchema = (t: TFunction<'validation'>) => ({
  ...validatePropertyAddress(t),
  property: yup.mixed(),
  parent: yup.mixed(),
  portfolio: yup.mixed(),
  propertyNameEn: varcharValidation(t),
  propertyNameAr: varcharValidation(t),
  area: varNumberValidation(t).integer(t('invalid')).max(999999, t('invalid')),
  parkingSlot: varNumberValidation(t).integer(t('invalid')),
  units: varNumberValidation(t).integer(t('invalid')),
  // nearbyServices: varNumberValidation(t).integer(t('invalid')),
  floors: varNumberValidation(t)
    .integer(t('invalid'))
    .max(999999, t('invalid')),
  floorArea: varNumberValidation(t)
    .integer(t('invalid'))
    .max(999999, t('invalid')),
  elevators: varNumberValidation(t).integer(t('invalid')),
  showrooms: varNumberValidation(t).integer(t('invalid')),
  beneficiaryProfile: yup
    .object()
    .typeError(t('invalid'))
    .required(t('required')),
  propertyType: yup
    .string()
    .typeError('invalid')
    .oneOf(['building'], t('invalid'))
    .required(t('required')),
});

export const filterUnitsSchema = (t: TFunction<'validation'>) => ({
  query: varcharValidation(t),
  area: yup.object().typeError(t('invalid')),
  unitStatus: yup.object().typeError(t('invalid')),
  contractStatus: yup.object().typeError(t('invalid')),
  type: yup.object().typeError(t('invalid')),
});

export const addLandGeneralInfoSchema = (t: TFunction<'validation'>) => ({
  ...validatePropertyAddress(t),
  property: yup.mixed(),
  parent: yup.mixed(),
  portfolio: yup.mixed(),
  propertyNameEn: varcharValidation(t),
  propertyNameAr: varcharValidation(t),
  owner: varcharValidation(t),
  ownerPercentage: varNumberValidation(t).max(100, t('invalid')),
  area: varNumberValidation(t).integer(t('invalid')).max(999999, t('invalid')),
  whiteLand: yup.string().typeError(t('invalid')),
  propertyType: yup
    .string()
    .typeError('invalid')
    .oneOf(['land'], t('invalid'))
    .required(t('required')),
  beneficiaryProfile: yup
    .object()
    .typeError(t('invalid'))
    .required(t('required')),
});

export const addVillaGeneralInfoSchema = (t: TFunction<'validation'>) => ({
  ...validatePropertyAddress(t),
  property: yup.mixed(),
  parent: yup.mixed(),
  portfolio: yup.mixed(),
  propertyNameEn: varcharValidation(t),
  propertyNameAr: varcharValidation(t),
  floors: varNumberValidation(t),
  floorArea: varNumberValidation(t)
    .integer(t('invalid'))
    .max(999999, t('invalid')),
  area: varNumberValidation(t).integer(t('invalid')).max(999999, t('invalid')),
  roomCount: varNumberValidation(t),
  bathCount: varNumberValidation(t),
  bathWithShowerCount: varNumberValidation(t),
  bedroomCount: varNumberValidation(t),
  commonRoomCount: varNumberValidation(t),
  standalone: yup.object().typeError(t('invalid')).required(t('required')),
  propertyType: yup
    .string()
    .typeError('invalid')
    .oneOf(['villa'], t('invalid'))
    .required(t('required')),
  beneficiaryProfile: yup
    .object()
    .typeError(t('invalid'))
    .required(t('required')),
});

export const addShowroomGeneralInfoSchema = (t: TFunction<'validation'>) => ({
  ...validatePropertyAddress(t),
  property: yup.mixed(),
  parent: yup.mixed(),
  portfolio: yup.mixed(),
  propertyNameEn: varcharValidation(t),
  propertyNameAr: varcharValidation(t),
  area: varNumberValidation(t).integer(t('invalid')).max(999999, t('invalid')),
  floors: varNumberValidation(t),
  showroomType: varcharValidation(t),
  infoID: yup.string().typeError(t('invalid')),
  propertyType: yup
    .string()
    .typeError('invalid')
    .oneOf(['showroom'], t('invalid'))
    .required(t('required')),
  beneficiaryProfile: yup
    .object()
    .typeError(t('invalid'))
    .required(t('required')),
});

export const addApartmentGeneralInfoSchema = (t: TFunction<'validation'>) => ({
  ...validatePropertyAddress(t),
  property: yup.mixed(),
  parent: yup.mixed(),
  portfolio: yup.mixed(),
  propertyNameEn: varcharValidation(t),
  propertyNameAr: varcharValidation(t),
  area: varNumberValidation(t).integer(t('invalid')).max(999999, t('invalid')),
  standalone: yup.object().typeError(t('invalid')).required(t('required')),
  floorNumber: varNumberValidation(t),
  roomCount: varNumberValidation(t),
  bathCount: varNumberValidation(t),
  bathWithShowerCount: varNumberValidation(t),
  bedroomCount: varNumberValidation(t),
  commonRoomCount: varNumberValidation(t),
  infoID: yup.string().typeError(t('invalid')),
  owner: varcharValidation(t),
  ownerPercentage: varNumberValidation(t).max(100, t('invalid')),
  propertyType: yup
    .string()
    .typeError('invalid')
    .oneOf(['apartment'], t('invalid'))
    .required(t('required')),
  beneficiaryProfile: yup
    .object()
    .typeError(t('invalid'))
    .required(t('required')),
});

export const contactSchema = (t: TFunction<'validation'>) => ({
  name: varcharValidation(t),
  email: emailValidation(t),
  message: varcharValidation(t).max(999, t('invalid')),
});

export const addFeatureFormSchema = (t: TFunction<'validation'>) => ({
  featureNameEn: varcharValidation(t),
  featureNameAr: varcharValidation(t),
  featureDescriptionEn: optionalVarcharValidation(t),
  featureDescriptionAr: optionalVarcharValidation(t),
});

export const addFacilityFormSchema = (t: TFunction<'validation'>) => ({
  facilityNameEn: varcharValidation(t),
  facilityNameAr: varcharValidation(t),
  facilityDescriptionEn: optionalVarcharValidation(t),
  facilityDescriptionAr: optionalVarcharValidation(t),
});

export const AddUnitDetailsFormSchema = (t: TFunction<'validation'>) => ({
  property: yup.number().typeError(t('invalid')),
  price: varcharValidation(t),
  date: varcharValidation(t),
  descriptionEn: optionalVarcharValidation(t),
  descriptionAr: optionalVarcharValidation(t),
  type: yup
    .string()
    .typeError('invalid')
    .oneOf(['rent', 'sale', 'vacant', 'occupied'], t('invalid')),
});

export const AddPropertyDetailsFormSchema = (t: TFunction<'validation'>) => ({
  property: yup.mixed(),
  listOnTheMarketplace: yup.mixed(),
  finished: yup.mixed(),
  acquisitionPrice: varNumberValidation(t),
  acquisitionDate: varcharValidation(t),
  propertyDescriptionAr: optionalVarcharValidation(t),
  propertyDescriptionEn: optionalVarcharValidation(t),
  propertyStatus: yup
    .string()
    .typeError('invalid')
    .oneOf(['rent', 'sale'], t('invalid')),
  isOccupied: yup
    .string()
    .typeError('invalid')
    .oneOf(['True', 'False'], t('invalid'))
    .test('isForSaleVacant', t('should be vacant'), function (value) {
      const status = this.parent.propertyStatus;
      return !(status === 'sale' && value === 'True');
    }),
});

export const selectPropertyTypeSchema = (t: TFunction<'validation'>) => ({
  propertyType: yup
    .string()
    .typeError(t('required'))
    .oneOf(['building', 'villa', 'apartment', 'showroom', 'land'], t('invalid'))
    .required(t('required')),
});

export const createInvoiceSchema = (t: TFunction<'validation'>) => ({
  property: yup.object().required(t('required')),
  beneficiaryProfile: yup.object().required(t('required')),
  payerPhone: varcharValidation(t),
  payerEmail: emailValidation(t),
  payerFirstName: varcharValidation(t),
  payerLastName: varcharValidation(t),
  invoiceType: yup.object().required(t('required')),
  paymentPeriod: yup.string().required(t('required')),
  paymentDate: yup.date().typeError('invalid').required(t('required')),
  amount: varNumberValidation(t).max(999999, t('invalid')),
  comment: yup.string().required(t('required')).max(999, t('invalid')),
});

export const addManagerFormSchema = (t: TFunction<'validation'>) => ({
  firstName: varcharValidation(t),
  lastName: varcharValidation(t),
  email: emailValidation(t),
  language: yup
    .string()
    .oneOf(['en', 'ar'], t('invalid'))
    .required(t('required')),
});

export const addPortfolioFormSchema = (t: TFunction<'validation'>) => ({
  name: varcharValidation(t),
});

export const addAssignManagerFormSchema = (t: TFunction<'validation'>) => ({
  manager: yup.object().typeError(t('invalid')).required(t('required')),
});

export const addContractGeneralInfoSchema = (t: TFunction<'validation'>) => ({
  property: yup.object().typeError('invalid').required(t('required')),
  beneficiaryProfile: yup.object().typeError('invalid').required(t('required')),
});

export const AddContractNewTenantSchema = (t: TFunction<'validation'>) => ({
  phone: varcharValidation(t),
  nationalID: varcharValidation(t),
  firstName: varcharValidation(t),
  lastName: varcharValidation(t),
  email: emailValidation(t),
});

export const addContractDetailsFormSchema = (t: TFunction<'validation'>) => ({
  tenant: yup.number().positive(),
  beneficiaryProfile: yup.number().positive(),
  property: yup.number().positive(),
  name: varcharValidation(t),
  paymentFrequency: yup
    .object()
    .typeError(t('invalid'))
    .required(t('required')),
  startPaymentDate: yup.date().typeError(t('invalid')).required(t('required')),
  endPaymentDate: yup
    .date()
    .typeError(t('invalid'))
    .required(t('required'))
    .test('endPaymentDate', t('invalid'), function (value) {
      const contractStart = this.parent?.startPaymentDate;
      if (contractStart) {
        const startContract = dayjs(contractStart);
        const endContract = dayjs(value);
        return endContract.isAfter(startContract);
      }
      return false;
    }),
  amount: varNumberValidation(t).max(99999999, t('invalid')),
  comment: yup
    .string()
    .nullable()
    .typeError(t('invalid'))
    .max(255, t('too-long')),
  notificationType: yup
    .string()
    .oneOf(['sms', 'email'], t('invalid'))
    .required(t('required')),
  notificationLang: yup
    .string()
    .oneOf(['en', 'ar'], t('invalid'))
    .required(t('required')),
});

const rentTypes = ['daily', 'weekly', 'monthly', 'yearly'];
export const addRentSchema = (t: TFunction<'validation'>) => ({
  property: yup.object().typeError(t('invalid')).required(t('required')),
  contract: yup.object().typeError(t('invalid')).required(t('required')),
  tenant: yup.object().typeError(t('invalid')).required(t('required')),
  rentType: yup
    .string()
    .test('rentType', t('required'), function (value) {
      return rentTypes.includes(value?.toLowerCase() || '');
    })
    .required(t('invalid')),
  firstPaymentDate: yup.date().typeError(t('invalid')).required(t('required')),
  amount: yup
    .number()
    .typeError(t('invalid'))
    .positive(t('invalid'))
    .required(t('required')),
  startDate: yup
    .date()
    .typeError(t('invalid'))
    .required(t('required'))
    .test('startDate', t('invalid') + ' date', function (value) {
      const contractStart = this.parent?.contract?.startPaymentDate;
      const contractEnd = this.parent?.contract?.endPaymentDate;
      if (contractStart && contractEnd) {
        const startContract = dayjs(contractStart).subtract(1, 'days');
        const endContract = dayjs(contractEnd);
        const startRent = dayjs(value);
        return (
          startRent.isAfter(startContract) && startRent.isBefore(endContract)
        );
      }
      return false;
    }),
  endDate: yup
    .date()
    .typeError(t('invalid'))
    .required(t('required'))
    .test('startDate', t('invalid') + ' date', function (value) {
      const rentStart = this.parent?.startDate;
      const contractEnd = this.parent?.contract?.endPaymentDate;
      if (rentStart && contractEnd) {
        const startRent = dayjs(rentStart);
        const endContract = dayjs(contractEnd).add(1, 'days');
        const endRent = dayjs(value);
        return endRent.isAfter(startRent) && endRent.isBefore(endContract);
      }
      return false;
    }),
  moveInDate: yup
    .date()
    .typeError(t('invalid'))
    .required(t('required')),
  moveOutDate: yup
    .date()
    .typeError(t('invalid'))
    .required(t('required'))
    .test('moveOutDate', t('invalid'), function (value) {
      const moveIn = dayjs(this.parent?.moveInDate).subtract(1, 'days');
      const rentEnd = dayjs(this.parent?.endDate).add(1, 'days');
      const moveOut = dayjs(value);
      return moveOut.isBefore(rentEnd) && moveOut.isAfter(moveIn);
    }),

  rentRate: yup
    .number()
    .typeError(t('invalid'))
    .positive(t('invalid'))
    .required(t('required')),
});

export const selectTenantSchema = (t: TFunction<'validation'>) => ({
  tenant: yup.object().typeError('invalid').required(t('required')),
});

export const addNewMessageSchema = (t: TFunction<'validation'>) => ({
  property: yup.object().typeError('invalid').required(t('required')),
  title: varcharValidation(t),
  message: varcharValidation(t),
});

export const verifyPhoneFormSchema = (t: TFunction<'validation'>) => ({
  code: varcharValidation(t),
});
